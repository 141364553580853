export type Validator = (text: string) => boolean;

//

export const textValidator: Validator = (text: string) => {
	if (typeof text !== "undefined" && text !== null && String(text) !== "null") {
		return text.length > 3;
	} else {
		return false;
	}
};

export const numberValidator: Validator = (text: string) => {
	if (Number(text) === 0) {
		return true;
	} else if (typeof text === "number") {
		return true;
	} else if (text) {
		var pattern = new RegExp("^[0-9.]+$");
		return pattern.test(String(text));
	} else {
		return false;
	}
};

export const emailValidator: Validator = (text: string) => {
	return emailRegex.test(text);
};

export const passwordValidator: Validator = (text: string) => {
	if (!textValidator(text)) return false;
	return text.length >= 8;
};

export const urlValidator: Validator = (text: string) => {
	try {
		new URL(text);
		return true;
	} catch (error) {
		return false;
	}
};

export const accountNumberValidator: Validator = (text: string) => {
	return text.length >= 4 && text.length <= 16;
};

export const abaNumberValidator: Validator = (text: string) => {
	return abaRoutingNumberRegex.test(text);
};

export const swiftNumberValidator: Validator = (text: string) => {
	return swiftNumberRegex.test(text);
};

export const ibanNumberValidator: Validator = (text: string) => {
	return ibanNumberRegex.test(text);
};

export const zipValidator: Validator = (text: string) => {
	return usZipCodeRegex.test(text);
};

export const ssnValidator: Validator = (text: string) => {
	return SSNRegex.test(text);
};

//

export const emailRegex = /^\w+([\+|\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/;

export const swiftNumberRegex = /^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}[XXX0-9]{0,3}/;

export const ibanNumberRegex = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;

export const usZipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const abaRoutingNumberRegex = /^([0|1|2|3|6|7|8]{4})[0-9]{4}[0-9]$/;

export const SSNRegex = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;

export const urlRegex = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

//

export const alphabetAnyCaseNumbersRegex = /^[a-zA-Z0-9]+$/;

export const alphabetUpperCaseNumbersRegex = /^[A-Z0-9]*$/;

export const numbersOnlyRegex = /^[0-9]*$/;
