<script lang="ts" setup>
	import IMask, { InputMask, FactoryArg } from "imask";
	import { alphabetAnyCaseNumbersRegex } from "~/utils/validators";
	import { AutocompleteOptions, InputTypes } from "~/utils/types";

	const props = withDefaults(
		defineProps<{
			id?: string;
			name?: string;
			value: string;
			type: InputTypes;
			autocomplete?: AutocompleteOptions;
			maxlength?: number;
			disabled?: boolean;
			readonly?: boolean;
			placeholder?: string;
		}>(),
		{
			autocomplete: "off",
			multiline: false,
		}
	);

	const emit = defineEmits<{
		(e: "update:value", value: string): void;
		(e: "change", value: string): void;
		(e: "focusin"): void;
		(e: "focusout"): void;
		(e: "enterpressed"): void;
	}>();

	const element = ref<HTMLInputElement | HTMLTextAreaElement | undefined>();

	const value = computed({
		get() {
			return props.value;
		},
		set(value: string) {
			emit("update:value", value);
		},
	});

	const type = computed(() => {
		if (["text", "password", "email", "number"].includes(props.type)) {
			return props.type;
		} else {
			return "text";
		}
	});

	const instance = ref<InputMask<any>>();

	const mask = computed(() => {
		if (props.type === "email") {
			return {
				prepare: function (string: string) {
					return string.toLowerCase();
				},
				mask: /^([A-Za-z]|[0-9]|\.|\-|\_|\+|\@)+$/,
			} as FactoryArg;
		} else if (props.type === "iban-number" || props.type === "swift-number") {
			return {
				prepare: function (string: string) {
					return string.toUpperCase();
				},
				mask: alphabetAnyCaseNumbersRegex,
			} as FactoryArg;
		} else if (props.type === "aba-number" || props.type === "zip") {
			return {
				mask: /^[0-9]*$/,
			} as FactoryArg;
		} else if (props.type === "url") {
			return {
				// regex for anything but spaces
				mask: /^[^\s]*$/,
			} as FactoryArg;
		}
	});

	const maxlength = computed(() => {
		if (props.type === "email") {
			return 254;
		} else if (props.type === "iban-number") {
			return 34;
		} else if (props.type === "swift-number") {
			return 11;
		} else if (props.type === "aba-number") {
			return 9;
		} else if (props.type === "zip") {
			return 5;
		} else if (props.type === "url") {
			return 2048;
		} else {
			return props.maxlength;
		}
	});

	onMounted(function () {
		watch(
			[mask, element],
			() => {
				if (mask.value && element.value) {
					instance.value = IMask(element.value, mask.value);
					//const { unmaskedValue, typedValue, value } = useIMask(element, mask);
				} else if (instance.value) {
					instance.value.destroy();
				}
			},
			{ immediate: true }
		);
	});

	onBeforeUnmount(function () {
		if (instance.value) {
			instance.value.destroy();
		}
	});
</script>

<template>
	<input
		:placeholder="placeholder"
		:id="props.id"
		:type="type"
		:name="name"
		:autocomplete="autocomplete"
		:maxlength="maxlength"
		ref="element"
		v-model="value"
		@change="() => $emit('change', value)"
		@focusin="() => $emit('focusin')"
		@focusout="() => $emit('focusout')"
		:disabled="props.disabled"
		:readonly="props.readonly"
		@keyup.enter="() => emit('enterpressed')"
	/>
</template>

<style scoped lang="scss"></style>
